<template>
  <div>
    <el-card>
      <Combination :listName="title"></Combination>
      <div class="all-editor">
        <div class="label">
          <el-form
            :model="editors"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="message-formRule"
          >
            <el-form-item label="标题" prop="titles">
              <el-input v-model="editors.titles"></el-input>
            </el-form-item>
            <el-form-item label="标签" prop="labels">
              <el-select v-model="editors.labels" multiple placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button @click="addAnnouncement" type="primary">{{
                edit === 'add' ? '发布公告' : '修改公告'
              }}</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="editor-content">
          <vue-editor v-model="content"></vue-editor>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import { VueEditor } from 'vue2-editor';
import { mapActions } from 'vuex';
import Combination from '@/components/Combination.vue';
import { checkMessageTitle } from '@/util/proofread';
import { getCookie } from '@/util/userLoginFn';
import {
  addAnnouncements,
  editAnnouncements,
} from '@/service/moudules/platformApi';

export default {
  components: {
    VueEditor,
    Combination,
  },
  created() {
    let row = this.$route.query;
    if (Reflect.has(row, 'title')) {
      let { text, title, tag, isEdit } = row;
      this.editors.titles = title;
      this.editors.labels = tag;
      this.content = text;
      this.edit = isEdit;
      this.edit = 'edit';
    } else {
      this.editors.titles = '';
      this.editors.labels = '';
      this.content = '';
      this.edit = 'add';
    }
  },
  data() {
    return {
      getCookie,
      addAnnouncements,
      content: '',
      edit: 'add',
      title: '通知公告',
      editors: {
        labels: [],
        titles: '',
      },
      rules: {
        titles: [
          { validator: checkMessageTitle, trigger: 'blur', required: true },
        ],
      },
      options: [
        {
          value: 'home',
          label: 'Home',
        },
        {
          value: 'page',
          label: 'Page',
        },
      ],
    };
  },
  methods: {
    ...mapActions('individualcenter', ['getListUnreadFn']),
    async addAnnouncement() {
      try {
        let res;
        let params = {
          sessionId: this.getCookie('sessionId'),
          tag: this.editors.labels,
          text: this.content,
          title: this.editors.titles,
        };
        if(!this.editors.titles){
          this.$message.error('请填写标题')
          return
        }
        if(!this.editors.labels.length){
          this.$message.error('请选择标签')
          return
        }
        this.edit === 'add'
          ? (res = await addAnnouncements(params))
          : (res = await editAnnouncements(params));
        let { data, msg, code } = res;
        if (code !== 200) {
          this.$message({
            type: 'error',
            message: msg,
          });
          return false;
        }
        this.$message({
          type: 'success',
          message: this.edit === 'add' ? '添加公告成功' : '修改公告成功',
        });
        if(this.edit==='add'){
          this.getListUnreadFn()
        }
        this.$router.push({
          path: '/messageLogging',
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="less">
.all-editor {
  margin-left: 20px;
  .editor-content {
    display: flex;
    justify-content: flex-start;
    height: 600px;
    .quillWrapper {
      height: 80%;
    }
  }
  .label {
    .message-formRule {
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
    }
  }
}
</style>
